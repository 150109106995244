import React,{Fragment} from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import EditableInput from "../../Components/Elements/EditableInput";
import { usePage } from "../../Context/Page/Store";


const Content = ()=>{
    return(
        <Tabs defaultActiveKey={1} id="params-tabs">
            <Tab eventKey={1} title={"Pantalla de Bienvenida"} variant="pills">
                <WelcomeParams />
            </Tab>
        </Tabs>
    )
}
export default Content;

const WelcomeParams = ()=>{
    const {pageData} = usePage();
    const {welcomeLogged,welcomeUnlogged} = pageData;

    const list = [
        welcomeLogged,welcomeUnlogged
    ]
    return(
        <Row style={{marginTop:20}}>
            <EditableValuesMapper list={list}/>
        </Row>
    )
}

const EditableValuesMapper = ({list})=>{
    if(list.length>0){
        return list.map((textValue,indexText)=>(
            <Fragment>
                <Col xs="6">
                    <EditableInput 
                        iniValue={textValue.value||""}
                        label={textValue.label}
                        placeholder={"Inserte el mensaje de bienvenida."}
                        options={{
                            type:"update-param-value",
                            id:textValue.id,
                        }}
                    />
                </Col>
            </Fragment>
        ))
    }
    return (
        <Fragment>
            <p onClick={console.log({list})}>
             "List es demasiado corto"
            </p>
        </Fragment>
    );
}