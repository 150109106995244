import React, { Fragment,useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import Button from "../../../Components/Elements/Button";
import Input from "../../../Components/Elements/Input";
import ReturnIcon from "../../../Components/Elements/ReturnIcon";
import { REQUEST_IMAGE, Toast, useSystem } from "../../../Context/System";

const ModifyImage = (props)=>{
    const {handleModal,dataModal} = props;
    const {user,theme} = useSystem();
    const [file,setFile] = useState(null);
    const inputFileRef = useRef(null);
    const [isCreating,setIsCreating] = useState(false);
    const handleCreating = (param)=>setIsCreating(param);
    
    const checkErrors = ()=>{
        let valid = true;
        if(inputFileRef.current&&inputFileRef.current.files[0]){
            valid = false;
        }
        return valid;
    }
    const execute = async()=>{
        let formData = new FormData();
        formData.append("image-subcategory", inputFileRef.current.files[0]);
        formData.append("id",dataModal.idSubategorie);
        formData.append("type","update-subcategory-image");
        let r = await REQUEST_IMAGE({
            url:"subcategorie",
            formData,
            load:handleCreating
        })
        if(r.error){
            Toast({
                message:r.error
            })
        }else{
            if(r){
                Toast({
                    message:"Imágen actualizada exitosamente.",
                    type:"success"
                })
                handleModal(false)
            }else{
                Toast({
                    message:"Error al actualizar la imágen"
                })
            }

        }
    }
    const selectNewImage = ()=>inputFileRef.current.click();
    const returnFileName = (f)=>{
        if(f.indexOf("/")!==-1){
            let newStringArray = f.split("/");
            return newStringArray[newStringArray.length-1];
        }else{
            let newStringArray = f.split("\\");
            return newStringArray[newStringArray.length-1];
        }
    }
    return(
        <Fragment>
            <input type="file" accept="image/*" value={file} onChange={(e)=>setFile(e.target.value)} ref={inputFileRef} style={{display:"none"}} />
            <Row className="p-3">
                <Col xs="12" md="6">
                    <div className="d-flex justify-content-center">
                        Imágen actual
                    </div>
                    <div className="d-flex justify-content-center">
                        <img src={dataModal.image} style={{width:"90%",height:"auto",borderRadius:10,border:`solid 1px ${theme.colors.primary}`}} alt="" />
                    </div>
                </Col>
                <Col xs="12" md="6">
                    <div className="d-flex justify-content-center">
                        Nueva Imágen
                    </div>
                    <div onClick={selectNewImage} style={{padding:20,borderRadius:10,cursor:"pointer"}}>
                        <div className="d-flex justify-content-center">
                            <ReturnIcon 
                                nameIcon={file?"MdImage":"GiClick"}
                                color={theme.colors.secondary}
                                fontSize={150}
                            />
                        </div>
                        <div className="d-flex justify-content-center">
                            {file?returnFileName(file):"Seleccionar nueva imágen"}
                        </div>
                    </div>
                </Col>
            </Row>
            <div className="d-flex justify-content-center">
                <Button 
                    label="Actualizar Imágen"
                    load={isCreating}
                    execute={execute}
                    disabled={checkErrors()}
                />
            </div>
        </Fragment>
    )
}

export default ModifyImage;