import React, { Fragment,useState } from "react";
import Button from "../../../Components/Elements/Button";
import Input from "../../../Components/Elements/Input";
import NMSelect from "../../../Components/Elements/Select";
import { REQUEST, Toast, useSystem } from "../../../Context/System";

const ContentCreate = (props)=>{
    const {handleModal,dataModal} = props;
    const {planets} = dataModal;
    const {user,theme} = useSystem();
    const [name,setName] = useState("");
    const [description,setDescription] = useState("");
    const [selectedPlanet,setSelectedPlanet] = useState(null);
    const [isCreating,setIsCreating] = useState(false);
    const handleCreating = (param)=>setIsCreating(param);
    const checkName = ()=>{
        if(name&&name.length>=4){
            return null
        }else{
            return "El nombre debe contener al menos 4 caracteres."
        }
    }
    const checkDescription = ()=>{
        if(description&&description.length>=10){
            return null
        }else{
            return "La descripción debe contener al menos 4 caracteres."
        }
    }
    const checkErrors = ()=>{
        let valid = true;
        if(!checkName()&&!checkDescription()){
            valid = false;
        }
        return valid;
    }
    const execute = async()=>{
        let r = await REQUEST({
            url:"admin-request",
            params:{
                type:"create-zone",
                user,
                name,
                description,
                id_planet:selectedPlanet.value
            },
            load:handleCreating
        })
        if(r){
            if(r.error){
                Toast({
                    message:r.error,
                })
            }else{
                Toast({
                    message:"Zona creado exitosamente.",
                    type:"success"
                })
                handleModal(false);
            }
        }
    }
    return(
        <Fragment>
            <Input 
                value={name}
                setValue={setName}
                label="Nombre de la zona."
                error={checkName()}
            />
            <NMSelect 
                val={selectedPlanet}
                setVal={setSelectedPlanet}
                items={planets}
                label="Selecciona un planeta"
            />
            <span style={{color:theme.colors.primary}}>
                Descripción del planeta.
            </span>
            <textarea 
                className="form-control"
                cols="40" 
                rows="5" 
                value={description}
                onChange={(e)=>setDescription(e.target.value)}
                style={{
                    marginTop:10,
                    marginBottom:10
                }}
            />
            <div className="d-flex justify-content-center">
                <Button 
                    label="Crear"
                    load={isCreating}
                    execute={execute}
                    disabled={checkErrors()}
                />
            </div>
        </Fragment>
    )
}

export default ContentCreate;