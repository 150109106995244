import React, { Fragment,useState } from "react";
import Card from "../Elements/Card";
import Input from "../Elements/Input";
import "./styles.css";
import Button from "../Elements/Button";
import { useSystem,REQUEST,Toast } from "../../Context/System";
import md5 from "md5";

const Login = ()=>{
    const {constants,checkUser} = useSystem(); 
    const [email,setEmail] = useState("");
    const urlLogo = `${process.env.PUBLIC_URL}/img/logos/nm_logo_quad.png`;
    //const urlLogo = `${process.env.PUBLIC_URL}/img/logos/vitro_logo_round.png`;
    const [psw,setPsw] = useState("");
    const [load,setLoad] = useState(false);
    const handleLoad = (param)=>setLoad(param);
    const url_img_bg = process.env.PUBLIC_URL + '/img/fondo_nm_admin.png';
    const loginContainer = {
        backgroundImage:`url(${url_img_bg})`
    }

    const errorEmail = ()=>{
        if(!email){
            return "Insertar correo electrónico"
        }else{
            let reg = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
            if (reg.test(email)) {
                return null;
            }else{
                return "Formato de correo electrónico inválido";
            }
        }
    }
    const errorPsw = ()=>{
        if(!psw){
            return "Inserte su contraseña"
        }else{
            return null;
        }
    }
    const checkErrors = ()=>{
        if(!errorEmail()&&!errorPsw()){
            return false;
        }else{
            return true;
        }
    }
    const isWeb = ()=>window.screen.availWidth>=600;
    const loginFunc = async()=>{
        let r = await REQUEST({
            url:`${constants.backend}`,
            params:{
                type:"login",
                email,
                psw:md5(psw)
            },
            load:handleLoad
        })
        if(r.error){
            Toast({
                message:r.error
            });
        }else{
            localStorage.setItem("conection",r.conection);
            checkUser();
        }
    }
    return(
        <Fragment>
            <div className="backgroundContainer" style={loginContainer}>
                <Card>
                    <div className="form-login" style={{width:isWeb()?400:"92%"}}>
                        <div style={{padding:20}}>
                            <img src={urlLogo} style={{width:250,height:250}} />
                        </div>
                        <Input 
                            value={email} 
                            setValue={setEmail} 
                            label="Correo Electrónico" 
                            type="email" 
                            error={errorEmail()}
                        />
                        <Input 
                            value={psw} 
                            setValue={setPsw} 
                            label="Contraseña" 
                            type="password" 
                            error={errorPsw()}
                        />
                        <Button 
                            label="Iniciar Sesión"
                            load={load}
                            execute={loginFunc}
                            disabled={checkErrors()}
                        />
                    </div>
                </Card>
            </div>
        </Fragment>
    )
}
export default Login;