import React, { Fragment,useState } from "react";
import Button from "../../../Components/Elements/Button";
import Input from "../../../Components/Elements/Input";
import { REQUEST, Toast, useSystem } from "../../../Context/System";

const ModifyDescription = (props)=>{
    const {handleModal,dataModal} = props;
    const {user,theme} = useSystem();
    const [name,setName] = useState(dataModal.actualValue);
    const [isCreating,setIsCreating] = useState(false);
    const handleCreating = (param)=>setIsCreating(param);
    const checkName = ()=>{
        if(name&&name.length>=4){
            if(name!==dataModal.actualValue){
                return null;
            }else{
                return "La descripción no han cambiado."
            }
        }else{
            return "La descripción debe contener al menos 4 caracteres."
        }
    }
    const checkErrors = ()=>{
        let valid = true;
        if(!checkName()){
            valid = false;
        }
        return valid;
    }
    const execute = async()=>{
        let r = await REQUEST({
            url:"admin-request",
            params:{
                type:"update-video-description",
                user,
                value:name,
                id:dataModal.idVideo
            },
            load:handleCreating
        })
        if(r){
            if(r.error){
                Toast({
                    message:r.error,
                })
            }else{
                Toast({
                    message:"Video actualizado exitosamente.",
                    type:"success"
                })
                handleModal(false);
            }
        }
    }
    return(
        <Fragment>
            <Input 
                value={name}
                setValue={setName}
                label="Descripción del video."
                error={checkName()}
            />
            <div className="d-flex justify-content-center">
                <Button 
                    label="Actualizar Descripción"
                    load={isCreating}
                    execute={execute}
                    disabled={checkErrors()}
                />
            </div>
        </Fragment>
    )
}

export default ModifyDescription;