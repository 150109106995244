import React from 'react';
import { REQUEST, Toast, useSystem } from '../../../Context/System';
const EditableInput = React.createContext();
export const EditableInputProvider = (props)=>{
    const {user} = useSystem();
    const {iniValue,label,placeholder,options,successMsg,callback} = props;
    const [actualValue,setActualValue] = React.useState("");
    const [isLoading,setIsLoading] = React.useState(false);
    const handleLoading = (arg)=>setIsLoading(arg);

    const updateValue = async()=>{
        if(options){
            console.log(options)
            let r = await REQUEST({
                url:"admin-request",
                params:{
                    user,
                    newValue:actualValue,
                    ...options
                }
            })
            if(r.error){
                Toast({
                    message:r.error
                })
            }else{
                Toast({
                    message:successMsg||"Valor actualizado exitosamente.",
                    type:"success"
                })
                if(callback){
                    callback();
                }
            }
        }
    }

    React.useEffect(()=>{
        let newValue = iniValue||"";
        if(iniValue){
            setActualValue(newValue);
        }
    },[iniValue])
    const value = React.useMemo(()=>{
        return({
            options,
            label,
            placeholder,
            iniValue,
            actualValue,
            isLoading,
            handleLoading,
            setActualValue,
            updateValue
        })
    },[actualValue,iniValue,isLoading])
    return <EditableInput.Provider value={value} {...props}/>
}
export const useEditableInput = ()=> React.useContext(EditableInput);