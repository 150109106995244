import React, { Fragment,useState } from "react";
import { Row,Col,Card } from "react-bootstrap";
import ReturnIcon from "../../Components/Elements/ReturnIcon";
import { usePage } from "../../Context/Page/Store";
import { useSystem } from "../../Context/System";
import Button from "../../Components/Elements/Button";
import { usePrice,PriceProvider } from "./Context";
import CustomModal from "../../Components/Elements/Modal";
import ContentModal from "./ContentModal";
import number_format from "locutus/php/strings/number_format";

const Content = ()=>{
    return(
        <PriceProvider>
            <Modal />
            <Header />
            <Body />
        </PriceProvider>
    )
}
const Modal = ()=>{
    const {user} = useSystem();
    const {showModal,handleModal,dataModal} = usePrice();
    if(showModal&&dataModal){
        return(
            <CustomModal 
                    active={showModal}
                    handle={handleModal}
                    postObj={{
                        ...dataModal,
                        user
                    }}
                    url={"admin-request"}
                    children={<ContentModal  />}
                    title={dataModal.title||"Modal sin titulo."}
                    size="xl"
                    center
                />
        )
    }
    return null;
}
const Header = ()=>{
    const {openModal} = usePrice();
    return(
        <Fragment>
            <div className="d-flex justify-content-end">
                <Button 
                    type="secondary"
                    label="Crear Precio"
                    execute={()=>openModal({
                        type:"check-permit",
                        idPermit:18,
                        title: "Crear nuevo precio."
                    })}
                />
            </div>
        </Fragment>
    )
}
const Body = ()=>{
    const {pageData} = usePage();
    const {list} = pageData;
    if(list.length>0){
        return <PriceList />
    }else{
        return <NoPrices />
    }

}
const PriceList = ()=>{
    const {openModal,cutString} = usePrice();
    const {theme} = useSystem()
    const {pageData} = usePage();
    const {list,statusList} = pageData;
    return(
        <Fragment>
            <Row>
                {
                    list.map((price,priceIndex)=>(
                        <Col key={priceIndex} xs="12" md="4" style={{marginTop:10}}>
                            <Card style={{ width: '100%' }}>
                                <div className="d-flex justify-content-center p-2">
                                    <ReturnIcon 
                                        nameIcon="FaMoneyBillAlt"
                                        style={{
                                            color:theme.colors.primary,
                                            fontSize:100
                                        }}
                                    />
                                </div>
                                <Card.Body>
                                    <Card.Title 
                                        className="minisizeable" 
                                        style={{
                                            cursor:"pointer",
                                            textAlign:"center"
                                        }} 
                                        onClick={()=>openModal({
                                            type:"check-permit",
                                            idPermit:19,
                                            title: "Modificar nombre del precio.",
                                            actualValue:price.price_name,
                                            id:price.id_price
                                        })}
                                    >
                                        {price.price_name}
                                    </Card.Title>
                                    <Row 
                                        className="minisizeable" 
                                        style={{
                                            cursor:"pointer"
                                        }}
                                        onClick={()=>openModal({
                                            type:"check-permit",
                                            idPermit:21,
                                            title: "Modificar estatus de precio.",
                                            actualValue:{value:price.price_status,label:price.name_status},
                                            id:price.id_price
                                        })}
                                    >
                                        <Col xs="12" md="6">
                                            Estatus:
                                        </Col>
                                        <Col className="d-flex justify-content-end" xs="12" md="6">
                                        {price.name_status}
                                        </Col>
                                    </Row>
                                    <Row 
                                        className="minisizeable" 
                                        style={{
                                            cursor:"pointer"
                                        }}
                                        onClick={()=>openModal({
                                            type:"check-permit",
                                            idPermit:20,
                                            title: "Modificar descripción de precio.",
                                            actualValue:price.price_description,
                                            id:price.id_price
                                        })}
                                    >
                                        <Col xs="12" md="6">
                                            Descripción:
                                        </Col>
                                        <Col className="d-flex justify-content-end" xs="12" md="6">
                                        {cutString(price.price_description)}
                                        </Col>
                                    </Row>
                                    <Row 
                                        className="minisizeable" 
                                        style={{
                                            cursor:"pointer"
                                        }}
                                        onClick={()=>openModal({
                                            type:"check-permit",
                                            idPermit:22,
                                            title: "Modificar categorías asignadas.",
                                            actualValue:price.categories,
                                            id:price.id_price
                                        })}
                                    >
                                        <Col xs="12" md="6">
                                            Categorías asignadas:
                                        </Col>
                                        <Col className="d-flex justify-content-end" xs="12" md="6">
                                            {price.categories.length}
                                        </Col>
                                    </Row>
                                    <Row 
                                        className="minisizeable" 
                                        style={{
                                            cursor:"pointer"
                                        }}
                                        onClick={()=>openModal({
                                            type:"check-permit",
                                            idPermit:23,
                                            title: "Modificar costo de precio.",
                                            actualValue:{base:price.cost,tax:price.tax},
                                            id:price.id_price
                                        })}
                                    >
                                        <Col xs="12" md="6">
                                            Costo Cliente:
                                        </Col>
                                        <Col className="d-flex justify-content-end" xs="12" md="6">
                                            ${number_format((price.cost+price.tax),2)}
                                        </Col>
                                    </Row>
                                    <Row style={{cursor:"no-drop"}}>
                                        <Col xs="12" md="6">
                                            Creado por:
                                        </Col>
                                        <Col className="d-flex justify-content-end" xs="12" md="6">
                                            {price.created_price_names}
                                        </Col>
                                    </Row>
                                    <Row style={{cursor:"no-drop"}}>
                                        <Col xs="12" md="6">
                                            Creado en:
                                        </Col>
                                        <Col className="d-flex justify-content-end" xs="12" md="6">
                                            {price.price_created_date.replace("T", " / ").replace("Z","").replace(".000","")}
                                        </Col>
                                    </Row>
                                    <Row style={{cursor:"no-drop"}}>
                                        <Col xs="12" md="6">
                                            Modificado por:
                                        </Col>
                                        <Col className="d-flex justify-content-end" xs="12" md="6">
                                            {price.last_modif_names}
                                        </Col>
                                    </Row>
                                    <Row style={{cursor:"no-drop"}}>
                                        <Col xs="12" md="6">
                                            Última Modificación:
                                        </Col>
                                        <Col className="d-flex justify-content-end" xs="12" md="6">
                                            {price.last_modif_date.replace("T", " / ").replace("Z","").replace(".000","")}
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))
                }
            </Row>
        </Fragment>
    )
}
const NoPrices = ()=>{
    const {theme} = useSystem();
    const primaryColor = theme.colors.primary;
    const secondaryColor = theme.colors.secondary;
    return(
        <Fragment>
            <div className="d-flex justify-content-center">
                <div style={{width:300,borderStyle:"solid",borderWidth:2,borderColor:secondaryColor,padding:20,textAlign:"center",borderRadius:10}}>
                    <span style={{textAlign:"center",color:"#fff",fontWeight:"bold"}}>
                        Aún no se ha creado ningún precio.
                    </span>
                </div>
            </div>

        </Fragment>
    )
}
export default Content;