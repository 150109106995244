import React, { Fragment } from "react";
import { useSystem } from "../../Context/System";
import { RiLogoutCircleLine } from "react-icons/ri";
import {OverlayTrigger,Popover} from "react-bootstrap";
import ReturnIcon from "../Elements/ReturnIcon";
import { useHistory } from "react-router-dom";
import { Toast } from "../../Context/System";
import "./styles.css"

const NavBar = ()=>{
    const {theme,user,logOut,routes} = useSystem();
    //const url_logo = `${process.env.PUBLIC_URL}/img/logos/LOGO_WHITE_NAME.png`;
    const url_logo = `${process.env.PUBLIC_URL}/img/logos/nm_logo_quad.png`;
    const target = React.useRef(null);
    const history = useHistory();
    const navTo = (el)=>{
        if(el.auth){
            history.push(el.route);
        }else{
            Toast({
                message:"Permiso denegado."
            })
        }
    }
    return(
        <Fragment>
            <div className="navbar-container" >
                <div className="navbar-container-logo" onClick={()=>history.push("/")}>
                    <img src={url_logo} className="navbar-logo" />
                </div>
                <div className="navbar-container-routes">
                {
                    routes.map((module,index)=>(
                        <div key={index} className="navbar-dropdown" style={{cursor:"pointer"}} >
                            <div className="sizeable" style={{padding:4}}>
                                <ReturnIcon nameIcon={module.icon} marginTop={8} fontSize={35} style={{marginLeft:20}} />
                            </div>
                            <div className="navbar-dropdown-content">
                                <div className="d-flex justify-content-center">
                                    <span style={{color:"white",fontSize:10,fontWeight:"bold"}}>
                                        {module.name_module}
                                    </span>
                                </div>
                                {
                                    module.pages.map((view,index_view)=>(
                                        <div key={index_view} className="minisizeable" style={{marginTop:index_view>0?0:10}}>
                                            <div className="fila" onClick={()=>navTo(view)}>
                                                <div className="columna" style={{width:"20%"}}>
                                                    <ReturnIcon nameIcon={view.icon} fontSize={25} />
                                                </div>
                                                <div className="columna" style={{width:"70%"}}>
                                                    <p style={{fontWeight:"bold",color:"#fff",fontSize:15}}>
                                                        {view.name_view}
                                                    </p>
                                                </div>
                                                <div className="columna" style={{width:"10%"}}>
                                                    {
                                                        (!view.auth)?
                                                        <ReturnIcon nameIcon={"FaLock"} fontSize={13} color="#707070" /> : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    ))
                }
                </div>
                <div className="navbar-user-container">
                    <div className="d-flex justify-content-end">
                        <div className="user-container">
                            <img src={user.imagen||"https://www.aguayodevs.com/user_profile.png"} className="user-img" />
                            <div className="user-info">
                                <span className="user-info-text">
                                    {
                                        user.names + " " + user.s_names
                                    }
                                </span> <br />
                                <span className="user-info-text">
                                    {
                                        user.name_user_type
                                    }
                                </span>
                            </div>
                            <OverlayTrigger
                                target={target.current}
                                placement={"bottom"}
                                overlay={
                                <Popover id={"exit-to-app-label"} >
                                    <Popover.Content>
                                    <div style={{textAlign:"center"}}>
                                        <strong className="text-center" style={{color:theme.colors.gray}}>
                                            Salir
                                        </strong>
                                    </div>
                                    </Popover.Content>
                                </Popover>
                                }
                            >
                            <div style={{}}>
                                <RiLogoutCircleLine onClick={logOut} style={{fontSize:50,color:"#fff",cursor:"pointer"}}/>
                            </div>
                            </OverlayTrigger>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default NavBar;