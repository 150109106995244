import React from "react";
import { ClipLoader } from "react-spinners";
import { useSystem } from "../../../Context/System";
import "./styles.css";
export const Loader = ({color,size,style})=>{
    const {theme} = useSystem();
    const {colors} = theme;
    return <ClipLoader color={color||colors.primary} loading={true} css={style} size={size||150} />
}
export const FullLoader = (props)=>{
    const {msg} = props;
    const url_img_bg = process.env.PUBLIC_URL + '/img/fondo_nm_admin.png';
    return(
        <div className="full-loader" style={{backgroundImage:`url(${url_img_bg})`}}>
            <div style={{marginTop:40}}>
                <div className="d-flex justify-content-center">
                    <Loader />
                </div>
                <p className="text-loader">
                    {msg}
                </p>
            </div>
        </div>
    )
}