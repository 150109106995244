import React, {Fragment, useState} from "react";
import {Row,Col} from "react-bootstrap"
import Button from "../../Components/Elements/Button";
import Input from "../../Components/Elements/Input";
import CustomModal from "../../Components/Elements/Modal";
import { usePage } from "../../Context/Page/Store";
import { useSystem } from "../../Context/System";
import ContentModalInfo from "./ContentModalInfo";

const Content = (props)=>{
    const {theme,user} = useSystem();
    const {
        pageData, 
        //pageLoad,
        //pageError,
        //handlePageLoad,
        //NAVTO
    } = usePage();
    const {list} = pageData;
    const [search,setSearch] = useState("");
    const [selectedUsr,setSelectedUsr] = useState(null);
    const [modalState,setModalState] = useState(false);
    const handleModal = (usr)=>{
        if(usr){
            setSelectedUsr(usr);
        }else{
            setSelectedUsr(null);
        }
        setModalState(!modalState);
    }

    const filteredList = ()=>{
        if(search){
            let searching = search.trim().toLowerCase();
            let filteredResult = list.filter(el=>(`${el.names.toLowerCase()} ${el.s_names.toLowerCase()}`).indexOf(searching)!==-1);
            return filteredResult;
        }else{
            return list;
        }
    }
    return(
        <Fragment>
            {
                selectedUsr ? 
                <CustomModal 
                    active={modalState}
                    handle={handleModal}
                    postObj={{
                        type:"check-permit",
                        user,
                        idPermit:3
                    }}
                    url={"admin-request"}
                    children={<ContentModalInfo handle={handleModal} />}
                    title={`Información cliente: "${selectedUsr.names} ${selectedUsr.s_names}"`}
                    size="xl"
                /> : null
            }
            <Row>
                <Col xs="12">
                    <Input 
                        label="Buscar"
                        value={search}
                        setValue={setSearch}
                        placeholder="Buscar por nombre"
                    />
                </Col>
                <Col xs="12">
                    <table className="table table-hover">
                        <thead>
                            <tr align="center">
                                <th style={{width:"25%",color:theme.colors.primary}}>
                                    Usuario
                                </th>
                                <th style={{width:"25%",color:theme.colors.primary}}>
                                    Correo
                                </th>
                                <th style={{width:"25%",color:theme.colors.primary}}>
                                    Tipo de usuario
                                </th>
                                <th style={{width:"25%",color:theme.colors.primary}}>
                                    Información
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                filteredList().map((usr,indexUsr)=>(
                                    <tr align="center" key={indexUsr}>
                                        <td style={{color:theme.colors.secondary}}>
                                            {
                                                `${usr.names} ${usr.s_names}`
                                            }
                                        </td>
                                        <td style={{color:theme.colors.secondary}}>
                                            {
                                                `${usr.email}`
                                            }
                                        </td>
                                        <td style={{color:theme.colors.secondary}}>
                                            {
                                                `${usr.userType}`
                                            }
                                        </td>
                                        <td>
                                            <Button 
                                                label="Mas información"
                                                execute={()=>handleModal(usr)}
                                            /> 
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </Col>
            </Row>
        </Fragment>
    )
}
export default Content;