import React, { Fragment } from "react";
import Card from "../Card";
import ReturnIcon from "../ReturnIcon";
import { useHistory } from "react-router-dom";
import "./styles.css";
import { useSystem } from "../../../Context/System";

const ErrorContainer = (props)=>{
    const {msg} = props;
    const history = useHistory();
    const {theme} = useSystem();
    const url_img_bg = process.env.PUBLIC_URL + '/img/fondo_nm_admin.png';
    return(
        <Fragment>
            <div className="principal" style={{backgroundImage:`url(${url_img_bg})`}}>
                <div className="d-flex justify-content-center">
                    <div style={{width:300,padding:20}}>
                        <Card onClick={()=>history.goBack()} sizeable={true} style={{backgroundColor:theme.colors.secondary,border:`solid ${theme.colors.gray} 2px`,cursor:"pointer"}}>
                            <div className="d-flex justify-content-center">
                                <ReturnIcon 
                                    nameIcon="MdError"
                                    color={theme.colors.gray}
                                    fontSize={150}
                                    />
                            </div>
                            <div className="d-flex justify-content-center">
                                <p style={{color:theme.colors.primary,fontWeight:"bold",textAlign:"center",fontSize:30}}>
                                    ERROR
                                </p>
                            </div>
                            <p style={{color:theme.colors.gray,fontWeight:"bold",textAlign:"center",fontSize:20}}>
                                {msg||"Error no definido"}
                            </p>
                            <p style={{color:theme.colors.primary,fontWeight:"bold",textAlign:"center",fontSize:15}}>
                                CLICK PARA REGRESAR
                            </p>
                        </Card>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default ErrorContainer;