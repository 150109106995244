import React,{useState} from "react";
import Button from "../../Components/Elements/Button";
import {Card, Col, Row} from "react-bootstrap"
import CustomModal from "../../Components/Elements/Modal";
import NMSelect from "../../Components/Elements/Select";
import { usePage } from "../../Context/Page/Store";
import { useSystem } from "../../Context/System";
import ContentModal from "./ContentModal";
import ErrorComponent from "../../Components/Elements/ErrorComponent";

const Content = ()=>{
    const { theme,user,imageDoNotExist } = useSystem()
    const {pageData,refreshPageData,NAVTO} = usePage();
    const {subList,catList} = pageData;
    const [search,setSearch] = useState("");
    const [selectedCategory,setSelectedCategory] = useState(null);
    const [showModal,setShowModal] = useState(false);
    const [loadModal,setShowLoadModal] = useState(false);
    const [dataModal,setDataModal] = useState(null);
    const handleModal = (param)=>{
        if(param){
            openModal(param)
        }else{
            closeModal();
        }
    }


    const openModal = (params)=>{
        setShowLoadModal(false);
        setDataModal(params);
        setShowModal(true);
    }
    const closeModal = ()=>{
        setShowModal(false);
        setShowLoadModal(false);
        setDataModal(null);
        refreshPageData({load:false})
    }

    const filterList = ()=>{
        if(selectedCategory){
            return subList.filter((el)=>el.id_categorie===selectedCategory.value)
        }else{
            return subList;
        }
    }
    const cutString = (str)=>{
        if(str){
            return str.substring(0, 10) + "...";
        }else{
            return ""
        }
    }
    return(
        <div>
            {
                showModal ? 
                <CustomModal 
                    active={showModal}
                    handle={handleModal}
                    postObj={{
                        ...dataModal,
                        user
                    }}
                    url={"admin-request"}
                    children={<ContentModal dataModal={dataModal} handleModal={handleModal} />}
                    title={dataModal.title}
                    size="xl"
                    center
                /> : null
            }
            <div className="row">
                <div className="col-9">
                    <NMSelect 
                        items={catList}
                        value={selectedCategory}
                        setVal={setSelectedCategory}
                        placeholder="Seleccione una categoría."
                    />
                </div>
                <div className="col-3">
                    <div className="d-flex justify-content-end">
                        <Button 
                            label="Crear Subcategoría"
                            load={loadModal}
                            execute={()=>openModal({
                                type:"check-permit",
                                idPermit:8,
                                title:`Crear nueva subcategoría dentro de: '${selectedCategory.label}'.`,
                                id_categorie:selectedCategory.value
                            })}
                            disabled={selectedCategory?false:true}
                        />
                    </div>
                </div>
            </div>
            <Row style={{padding:10}}>
                {
                    filterList().map((subCat,indexSubCat)=>(
                        <Col xs="12" md="4" style={{marginTop:10}}>
                            <Card style={{ width: '100%' }}>
                                <Card.Img 
                                    variant="top" 
                                    style={{cursor:"pointer"}}
                                    className="minisizeable"
                                    src={subCat.image||imageDoNotExist}onClick={()=>openModal({
                                        type:"check-permit",
                                        idPermit:14,
                                        title: `Modificar imágen '${subCat.name_subcategorie}'`,
                                        image:subCat.image||imageDoNotExist,
                                        idSubategorie:subCat.id_subcategorie
                                    })}
                                />
                                <Card.Body>
                                    <Card.Title className="minisizeable" style={{cursor:"pointer"}} onClick={()=>openModal({
                                        type:"check-permit",
                                        idPermit:9,
                                        title: `Modificar nombre '${subCat.name_subcategorie}'`,
                                        actualValue:subCat.name_subcategorie,
                                        idSubcategorie:subCat.id_subcategorie
                                    })}>
                                        {subCat.name_subcategorie}
                                    </Card.Title>
                                    <Row className="minisizeable" style={{cursor:"pointer"}} onClick={()=>openModal({
                                        type:"get-status-list",
                                        idPermit:13,
                                        title: `Modificar descripción '${subCat.name_subcategorie}'`,
                                        actualValue:subCat.description,
                                        idSubcategorie:subCat.id_subcategorie
                                    })}>
                                        <Col xs="12" md="6">
                                            Descripción:
                                        </Col>
                                        <Col className="d-flex justify-content-end" xs="12" md="6">
                                        {cutString(subCat.description)}
                                        </Col>
                                    </Row>
                                    <Row className="minisizeable" style={{cursor:"pointer"}} onClick={()=>openModal({
                                        type:"get-status-list",
                                        idPermit:null,
                                        title: "Actualizar estatus de categoría.",
                                        actualValue:{value:subCat.id_status,label:subCat.name_status},
                                        idSubcategorie:subCat.id_subcategorie
                                    })}>
                                        <Col xs="12" md="6">
                                            Estatus:
                                        </Col>
                                        <Col className="d-flex justify-content-end" xs="12" md="6">
                                        {subCat.name_status}
                                        </Col>
                                    </Row>
                                    <Row style={{cursor:"no-drop"}}>
                                        <Col xs="12" md="6">
                                            Creado por:
                                        </Col>
                                        <Col className="d-flex justify-content-end" xs="12" md="6">
                                            {subCat.createdUserName}
                                        </Col>
                                    </Row>
                                    <Row style={{cursor:"no-drop"}}>
                                        <Col xs="12" md="6">
                                            Creado en:
                                        </Col>
                                        <Col className="d-flex justify-content-end" xs="12" md="6">
                                            {subCat.created_date.replace("T", " / ").replace("Z","").replace(".000","")}
                                        </Col>
                                    </Row>
                                    <Row style={{cursor:"no-drop"}}>
                                        <Col xs="12" md="6">
                                            Modificado por:
                                        </Col>
                                        <Col className="d-flex justify-content-end" xs="12" md="6">
                                            {subCat.updatedUserName}
                                        </Col>
                                    </Row>
                                    <Row style={{cursor:"no-drop"}}>
                                        <Col xs="12" md="6">
                                            Última Modificación:
                                        </Col>
                                        <Col className="d-flex justify-content-end" xs="12" md="6">
                                            {subCat.last_modif.replace("T", " / ").replace("Z","").replace(".000","")}
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))
                }
                {
                    
                    filterList().length===0?
                        <ErrorComponent 
                            errorObj={{
                                errorMsg:"Parece que no hay subcategorías dentro de esta categoría."
                            }}
                        /> : null
                }

            </Row>
            {
                /*
            <div className="table-container p-2" style={{height:300,overflowY:"auto",padding:10}}>
                <table className="table table-striped" border="1px">
                    <thead>
                        <tr align="center" style={{color:theme.colors.primary}}>
                            <th scope="col" style={{width:"14%"}}>Nombre</th>
                            <th scope="col" style={{width:"14%"}}>Descripción</th>
                            <th scope="col" style={{width:"14%"}}>Estatus</th>
                            <th scope="col" style={{width:"14%"}}>Creado Por</th>
                            <th scope="col" style={{width:"14%"}}>Creado En</th>
                            <th scope="col" style={{width:"14%"}}>Última Modificación (Usuario)</th>
                            <th scope="col" style={{width:"14%"}}>Última Modificación (fecha)</th>
                        </tr>
                    </thead>
                    <tbody>

                    </tbody>
                    {
                        filterList().length===0?
                        <tr align="center">
                            <td colSpan={7}>
                                No existe ninguna subcategoría en la categoría seleccionada.
                            </td>
                        </tr>
                        :
                        filterList().map((subCat,indexSub)=>(
                            <tr key={indexSub} align="center">
                                <td style={{cursor:"pointer",padding:5}} onClick={()=>openModal({
                                    type:"check-permit",
                                    idPermit:6,
                                    title: "Modificar nombre de subcategoría.",
                                    actualValue:subCat.name_subcategorie,
                                    idCategorie:subCat.value
                                })}>
                                    {subCat.name_subcategorie}
                                </td>
                                <td style={{cursor:"pointer",padding:5}} onClick={()=>openModal({
                                    type:"check-permit",
                                    idPermit:6,
                                    title: "Modificar descripción de subcategoría.",
                                    actualValue:subCat.description,
                                    idCategorie:subCat.value
                                })}>
                                    {cutString(subCat.description)}
                                </td>
                                <td style={{cursor:"pointer",padding:5}} onClick={()=>openModal({
                                    type:"get-status-list",
                                    idPermit:null,
                                    title: "Actualizar estatus de categoría.",
                                    actualValue:{value:subCat.id_status,label:subCat.name_status},
                                    idCategorie:subCat.value
                                })}>
                                    {subCat.name_status}
                                </td>
                                <td style={{cursor:"no-drop",padding:5}} >
                                    {subCat.createdUserName}
                                </td>
                                <td style={{cursor:"no-drop",padding:5}} >
                                    {subCat.created_date.replace("T", " / ").replace("Z","").replace(".000","")}
                                </td>
                                <td style={{cursor:"no-drop",padding:5}} >
                                    {subCat.updatedUserName}
                                </td>
                                <td style={{cursor:"no-drop",padding:5}} >
                                    {subCat.last_modif.replace("T", " / ").replace("Z","").replace(".000","")}
                                </td>
                            </tr>
                        ))
                    }
                </table>
            </div>
                */
            }
        </div>
    )
}
export default Content;

const ShowSubcategories = ()=>{

}