import React from "react";
import ContentCreate from "./ContentCreate";
import ModifyName from "./ModifyName";
import ModifyStatus from "./ModifyStatus";
import ModifyDescription from "./ModifyDescription";
import ModifyCost from "./ModifyCost";
import ModifyCategories from "./ModifyCategories";
import { usePrice } from "../Context";

const ContentModal = (props)=>{
    const {dataModal} = usePrice();
    switch (dataModal.idPermit) {
        case 19:
            return <ModifyName />
        case 20:
            return <ModifyDescription />
        case 21:
            return <ModifyStatus />
        case 22:
            return <ModifyCategories />
        case 23:
            return <ModifyCost />
        default:
            return <ContentCreate />
    }
}
export default ContentModal;