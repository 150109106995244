import React from "react";
import Select from 'react-select';
import {FaWindowClose} from "react-icons/fa";
import {Row,Col} from "react-bootstrap";
import { useSystem } from "../../Context/System";

const Selector = (props) => {
  const {theme} = useSystem();
  const {val,setVal,items,label,placeholder,multi,index,styleContainer,isDisabled,styleSelect,iconDisabled,load,dontShowX} = props;
  const {colors} = theme;
  const styles = {
    control: (provided, state)=> ({
      ...provided,
      ...styleContainer
    }),
    option: (provided, state) => ({
      ...provided,
      color: colors.primary,
      ...styleSelect,
    }),
  }
  return(
    <Row>

      <Col xs={val&&!dontShowX?"11":"12"}>
        {
          label? <label style={{color:theme.colors.primary}}>
            {label}
          </label> : null
        }
        {
          (multi)?
          <Select
            isMulti
            value={val}
            onChange={(value)=>setVal(value,index)}
            options={items}
            placeholder={(placeholder)?placeholder:"Seleccione una opción"}
            className="basic-multi-select"
            classNamePrefix="select"
            style={styles}
            isDisabled={isDisabled}
          /> :
          <Select
            value={val}
            onChange={(value)=>setVal(value,index)}
            options={items}
            placeholder={(placeholder)?placeholder:"Seleccione una opción"}
            style={styles}
            isDisabled={isDisabled}
          />
        }
      </Col>
      {
        val&&!dontShowX? 
        <Col xs="1">
          <div style={{width:"100%",height:"100%",justifyContent:"center",alignItems:"center"}}>
            {
              load ? 
              <div style={{marginTop:label?30:0}}>
                ...
              </div>  
              :
              <FaWindowClose onClick={!iconDisabled?()=>setVal(null,index):null} style={{color:!iconDisabled?"red":"#a1a1a1",fontSize:20,marginTop:label?40:10,cursor:!iconDisabled?"pointer":"no-drop"}} />
            }
          </div>
        </Col> : null
      }
    </Row>
  )
};
export default (Selector);
