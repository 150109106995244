import React,{useState} from "react";
import DefaultConfig from "./preset";
import axios from "axios";
import toast from "react-hot-toast";
//export const BACKEND = "http://localhost:8081/";
export const BACKEND = "http://nanymagicaoficial.com:8081/";

const SystemContext = React.createContext();

export const SystemProvider = (props)=>{
    const {config,production} = props;
    const imageDoNotExist = "http://aguayodevs.com/images/noImage.png";
    const [theme] = useState(config||DefaultConfig);
    const [user,setUser] = useState(null);
    const [routes,setRoutes] = useState([]);
    const [loadUser,setLoadUser] = useState(false);
    const handleLoadUser = (param)=>setLoadUser(param);
    const constants = {
        backend: production ? BACKEND : "http://localhost:8081/",
    }

    const refreshUserData = async({load})=>{
        let r = await REQUEST({
            url:`${constants.backend}`,
            params:{
                type:"refreshUserData",
                conection:localStorage.conection
            },
            load:load?handleLoadUser:null
        })
        if(r.error){
            Toast({
                message:r.error
            });
        }else{
            setUser(r.userData);
            setRoutes(r.routes);
            Toast({
                message:`Bienvenido ${r.userData.names}`,
                type:"success"
            })
        }
    }
    const checkUser = ()=>{
        let conection = localStorage.conection || null;
        console.log("Se ejecuto checkUser",conection)
        if(conection){
            refreshUserData({load:true});
        }else{
            setUser(null);
        }
    }
    const logOut = ()=>{
        localStorage.clear();
        Toast({
            message:`Hasta pronto ${user.names}`,
            type:"success"
        })
        checkUser();
        setTimeout(()=>window.location.href="/",1000)
    }
    React.useEffect(()=>{
        if(!user){
            checkUser(true);
        }
    },[user])
    const value = React.useMemo(()=>{
        return({
            theme,
            production,
            constants,
            user,
            loadUser,
            logOut,
            checkUser,
            routes,Toast,
            imageDoNotExist
        })// eslint-disable-next-line
    },[user,loadUser,routes])
    return <SystemContext.Provider value={value} {...props}/>
}

export const useSystem = ()=> React.useContext(SystemContext);

export const REQUEST = ({url,params,config,load})=>{
    axios.defaults.baseURL = BACKEND;
    return new Promise((res)=>{
        (async()=>{
            if(load){
                load(true);
            }
            console.log(`Consultando: "${BACKEND}${url}"`)
            let r = await axios.post(url,params,config).catch(e=>{
                console.log("Error:",e);
                res({
                    error:"Error al conectar al servidor"
                })
            })
            if(r){
                res(r.data)
                setTimeout(()=>{
                    if(load){
                        load(false);
                    }
                },300)
            }else{
                res({
                    error:"Error al conectar al servidor."
                })
                if(load){
                    load(false);
                }
            }
        })()
    })
}
export const REQUEST_IMAGE = ({url,formData,load})=>{
    axios.defaults.baseURL = BACKEND;
    return new Promise((res)=>{
        (async()=>{
            if(load){
                load(true);
            }
            const config = {'content-type': 'multipart/form-data',}
            let r = await axios.post(`/admin-request/update-image/${url}`,formData,config).catch(e=>{
                console.log("Error:",e);
                res({
                    error:"Error al conectar al servidor"
                })
            })
            if(r){
                res(r.data)
            }else{
                res({
                    error:"Error al conectar al servidor."
                })
            }
            setTimeout(()=>{
                if(load){
                    load(false);
                }
            },300)
        })()
    })
}

export const Toast = ({message,type})=>{
    let msg = message||"Mensaje no definido.";
    switch (type) {
        case "success":
            toast.success(msg, {
                duration: 5000,
                style: {
                    border: '3px solid #761d90',
                    padding: '16px'
                },
                iconTheme: {
                  primary: "#761d90",
                  secondary: '#FFFAEE',
                },
            });
            break;
        default:
            toast.error(msg, {
                duration: 5000,
                style:{
                    border: '3px solid #fbf37c',
                    padding: '16px'
                },
                iconTheme: {
                    primary: "#f1b900",
                    secondary: 'red',
                  },
            })
            break;
    }
};