import React, {Fragment,useState} from "react";
import { useModalContext } from "../../../Components/Elements/Modal/Store";
import Selector from "../../../Components/Elements/Select";
import { REQUEST, Toast, useSystem } from "../../../Context/System";

const ModifyStatus = (props)=>{
    const {dataModal,handleModal} = props;
    const {id} = dataModal;
    const {user} = useSystem();
    const {modalData,refreshInfo} = useModalContext();
    const [selectedStatus,setSelectedStatus] = useState(dataModal.actualValue);
    const {statusList} = modalData;

    const changeStatus = async(value)=>{
        console.log(id);
        let r = await REQUEST({
            url:"admin-request",
            params:{
                type:"update-planet-status",
                value:value.value,
                user,
                id
            }
        })
        if(r){
            if(r.error){
                Toast({
                    message:r.error
                })
            }else{
                Toast({
                    message:"Estatus actualizado exitosamente.",
                    type:"success"
                })
                handleModal(false)
            }
        }
    }
    return(
        <Fragment>
            <Selector 
                val={selectedStatus}
                setVal={changeStatus}
                items={statusList}
                label="Seleccione un estatus."
            />
        </Fragment>
    )
}
export default ModifyStatus;