import React, {Fragment} from "react";
import {Tabs,Tab} from "react-bootstrap";
import { TabContextProvider } from "../../../Components/Elements/TabContext/Store";
import ContentTabInfo from "./ContentTabs/ContentTabInfo";

const ContentModalInfo = ({handle})=>{

    return(
        <Fragment>
            <Tabs defaultActiveKey={0} id="tab-info">
                <Tab eventKey={0} title={"Principal"} variant="pills">
                    <TabContextProvider 
                        url={"admin-request"}
                        postData={{
                            type:"construction"
                        }}
                    >
                        <ContentTabInfo />
                    </TabContextProvider>
                </Tab>
                <Tab eventKey={1} title={"Pagos"} variant="pills">
                    <TabContextProvider 
                        url={"admin-request"}
                        postData={{
                            type:"construction"
                        }}
                    >
                        <ContentTabInfo />
                    </TabContextProvider>
                </Tab>
                <Tab eventKey={2} title={"Actividad"} variant="pills">
                    <TabContextProvider 
                        url={"admin-request"}
                        postData={{
                            type:"construction"
                        }}
                    >
                        <ContentTabInfo />
                    </TabContextProvider>
                </Tab>
                <Tab eventKey={3} title={"Más"} variant="pills">
                    <TabContextProvider 
                        url={"admin-request"}
                        postData={{
                            type:"construction"
                        }}
                    >
                        <ContentTabInfo />
                    </TabContextProvider>
                </Tab>
            </Tabs>
        </Fragment>
    )
}
export default ContentModalInfo;