import React from "react";
import Model from "./model";
import { EditableInputProvider } from "./store";

const EditableInput = (props)=>{

    return(
        <EditableInputProvider {...props}>
            <Model />
        </EditableInputProvider>
    )
}
export default EditableInput;