import {Fragment} from "react";
import { useSystem } from "../../Context/System";
import Card from "./Card";
import ReturnIcon from "./ReturnIcon";

const ErrorComponent = (props)=>{
    const {errorObj} = props;
    const {theme} = useSystem();
    const {onPress,errorMsg,tagPress,icon} = errorObj;
    return(
        <Fragment>
            <div className="d-flex justify-content-center" style={{width:"100%"}}>
                <div style={{width:300,padding:20}}>
                    <Card onClick={onPress?()=>onPress():null} sizeable={true} style={{backgroundColor:theme.colors.gray,border:`solid ${theme.colors.secondary} 2px`,cursor:"pointer",alignItems:"center"}}>
                        <div style={{width:"100%"}}>
                            <div className="d-flex justify-content-center">
                                <ReturnIcon 
                                    nameIcon={icon||"MdError"}
                                    color={theme.colors.primary}
                                    fontSize={150}
                                    />
                            </div>
                            <div className="d-flex justify-content-center">
                                <p style={{color:theme.colors.secondary,fontWeight:"bold",textAlign:"center",fontSize:30}}>
                                    ERROR
                                </p>
                            </div>
                            <p style={{color:theme.colors.secondary,fontWeight:"bold",textAlign:"center",fontSize:20}}>
                                {errorMsg||"Error no definido"}
                            </p>
                            <p style={{color:theme.colors.primary,fontWeight:"bold",textAlign:"center",fontSize:15}}>
                                {tagPress||null}
                            </p>
                        </div>
                    </Card>
                </div>
            </div>
        </Fragment>
    )
}
export default ErrorComponent;