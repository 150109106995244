import React from "react";
import ContentCreate from "./ContentCreate";
import ModifyDescription from "./ModifyDescription";
import ModifyImage from "./ModifyImage";
import ModifyName from "./ModifyName";
import ModifyStatus from "./ModifyStatus";

const ContentModal = (props)=>{
    const {dataModal} = props;
    switch (dataModal.idPermit) {
        case null:
            return <ModifyStatus {...props} />
        case 9:
            return <ModifyName {...props} />
        case 13:
            return <ModifyDescription {...props}/>
        case 14:
            return <ModifyImage {...props}/>
        default:
            return <ContentCreate {...props}/>
    }
}
export default ContentModal;