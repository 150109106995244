import React, {Fragment} from "react";
import { Col, Row } from "react-bootstrap";
import Card from "../../Components/Elements/Card";
import ReturnIcon from "../../Components/Elements/ReturnIcon";
import { usePage } from "../../Context/Page/Store";
import { useSystem } from "../../Context/System";


const Content = (props)=>{
    const {theme} = useSystem();
    const {
        pageData, 
        //pageLoad,
        //pageError,
        //handlePageLoad,
        NAVTO
    } = usePage();
    const {list} = pageData;
    return(
        <Fragment>
            <Row>
                {
                    list.map((el)=>(
                        <Col xs="12" md="4" className="mt-1">
                            <Card onClick={el.to?()=>NAVTO({to:el.to}):null} sizeable style={{cursor:el.to?"pointer":"no-drop"}}>
                                <div className="d-flex justify-content-center">
                                    <p style={{color:theme.colors.primary,fontSize:25,fontWeight:"bold"}}>
                                        {el.label}
                                    </p>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <ReturnIcon 
                                        nameIcon={el.icon}
                                        color={theme.colors.gray}
                                        fontSize={50}
                                    />
                                </div>
                                <div className="d-flex justify-content-center">
                                    <p style={{color:theme.colors.secondary,fontSize:50,fontWeight:"bold"}}>
                                        {el.count}
                                    </p>
                                </div>
                            </Card>
                        </Col>
                    ))
                }
            </Row>
        </Fragment>
    )
}
export default Content;