import React, { Fragment } from "react";
import {Switch,Route} from "react-router-dom";
import Home from "../../Screens/Home";
import AdminList from "../../Screens/AdminList";
import ClientList from "../../Screens/ClientList";
import AppParams from "../../Screens/Params";
import Categories from "../../Screens/Categories";
import Subcategories from "../../Screens/Subcategories";
import Videos from "../../Screens/Videos";
import Prices from "../../Screens/Prices";
import Planets from "../../Screens/Planets";
import Zones from "../../Screens/Zones";
export default function Nav(){
    return(
        <Switch>
            <Route exact path="/">
                <Home />
            </Route>
            <Route exact path="/admin-list">
                <AdminList />
            </Route>
            <Route exact path="/client-list">
                <ClientList />
            </Route>
            <Route exact path="/app-params">
                <AppParams />
            </Route>
            <Route exact path="/categories">
                <Categories />
            </Route>
            <Route exact path="/subcategories">
                <Subcategories />
            </Route>
            <Route exact path="/app-videos">
                <Videos />
            </Route>
            <Route exact path="/app-prices">
                <Prices />
            </Route>
            <Route exact path="/app-planets">
                <Planets />
            </Route>
            <Route exact path="/app-zones">
                <Zones />
            </Route>
            <Route>
                <div className="p-3 d-flex justify-content-center">
                    <span>
                        Contenido No Encontrado.
                    </span>
                </div>
            </Route>
        </Switch>
    )
}