import React, { Fragment } from "react";
import {SystemProvider,useSystem} from "../../Context/System";
import { FullLoader } from "../Elements/Loader";
import Nav from "../Router";
import Login from "../Login";
import "./styles.css";
const CONFIG = {
    colors: {
        primary: '#761d90',
        secondary: '#06c8de',
        gray: "#f1b900"
    },
}
const AppContainer = (props)=>{

    return(
        <SystemProvider config={CONFIG} production={true} >
            <AppContainerTunnel />
        </SystemProvider>
    )
}
export default AppContainer;

const AppContainerTunnel = (props)=>{
    const {user,loadUser} = useSystem();
    if(loadUser){
        return <FullLoader msg="Verificando su información.." />
    }
    if(user){
        return <Nav />
    }
    return <Login />
}