import React, {Fragment} from "react";
import Input from "../Input";
import { useEditableInput } from "./store";
import {Row,Col} from "react-bootstrap";
import { Loader } from "../Loader";
import { Toast, useSystem } from "../../../Context/System";
import ReturnIcon from "../ReturnIcon";
const Model = ()=>{
    const {iniValue,actualValue,setActualValue,label,placeholder,isLoading,options,updateValue} = useEditableInput();
    const {theme} = useSystem();

    const checkIsDifferent = ()=>{
        let isValid = false;
        if(iniValue!==actualValue){
            if(actualValue){
                isValid = true;
            }
        }
        return isValid;
    }
    const checkValue = ()=>{
        if(checkIsDifferent()){
            updateValue();
        }else{
            Toast({
                message:"El valor no ha cambiado o es inválido."
            })
        }
    }
    return(
        <Fragment>
            <Row>
                <Col xs="12" md={options?"9":"10"}>
                    <Input 
                        value={actualValue}
                        setValue={setActualValue}
                        label={label||"Nombre de valor no especificado."}
                        placeholder={placeholder||"Inserte un valor."}
                    />
                </Col>
                <Col xs="12" md={options?"3":"2"}>
                    {
                        isLoading ? 
                        <div className="d-flex justify-content-center">
                            <Loader 
                                color={theme.colors.primary}
                            />
                        </div> :
                        <Row>
                            {
                                options?
                                <Col xs="6">
                                    <div className="d-flex justify-content-center" onClick={checkValue}>
                                        <ReturnIcon 
                                            nameIcon={"FaSave"}
                                            color={checkIsDifferent()? theme.colors.primary : "#c1c1c1"}
                                            fontSize={30}
                                            style={{cursor:checkIsDifferent()?"pointer":"no-drop",marginTop:10}}
                                        />
                                    </div>
                                </Col> : null
                            }
                            <Col xs={options?"6":"12"}>
                                <div className="d-flex justify-content-center" onClick={()=>setActualValue("")}>
                                    <ReturnIcon 
                                        nameIcon={"GiCancel"}
                                        color={"red"}
                                        fontSize={30}
                                        style={{marginTop:10,cursor:"pointer"}}
                                    />
                                </div>
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
        </Fragment>
    )
}
export default Model;