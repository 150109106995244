import React, { Fragment,useState } from "react";
import Button from "../../../Components/Elements/Button";
import Input from "../../../Components/Elements/Input";
import NMSelect from "../../../Components/Elements/Select";
import { REQUEST, Toast, useSystem } from "../../../Context/System";

const ContentCreate = (props)=>{
    const {handleModal,dataModal} = props;
    const {categories,subcategories} = dataModal;
    const {user,theme} = useSystem();
    const [name,setName] = useState("");
    const [url,setUrl] = useState("");
    const [selectedCategorie,setSelectedCategorie] = useState(null);
    const [selectedSubcategorie,setSelectedSubcategorie] = useState(null);
    const [description,setDescription] = useState("");
    const [isCreating,setIsCreating] = useState(false);
    const handleCreating = (param)=>setIsCreating(param);
    const checkName = ()=>{
        if(name&&name.length>=4){
            return null
        }else{
            return "El nombre debe contener al menos 4 caracteres."
        }
    }
    const checkDescription = ()=>{
        if(description&&description.length>=10){
            return null
        }else{
            return "La descripción debe contener al menos 4 caracteres."
        }
    }
    const checkErrors = ()=>{
        let valid = true;
        if(!checkName()&&!checkDescription()&&selectedSubcategorie){
            valid = false;
        }
        return valid;
    }
    const execute = async()=>{
        let r = await REQUEST({
            url:"admin-request",
            params:{
                type:"create-video",
                user,
                url,
                idSubcategorie:selectedSubcategorie.value,
                name,
                description
            },
            load:handleCreating
        })
        if(r){
            if(r.error){
                Toast({
                    message:r.error,
                })
            }else{
                Toast({
                    message:"Video creado exitosamente.",
                    type:"success"
                })
                handleModal(false);
            }
        }
    }
    return(
        <Fragment>
            <Input 
                value={name}
                setValue={setName}
                label="Nombre del video."
                error={checkName()}
            />
            <Input 
                value={url}
                setValue={setUrl}
                label="Url del video (Formato HLS)."
                error={url?null:"El url no puede estar vacio."}
            />
            <NMSelect 
                val={selectedCategorie}
                setVal={setSelectedCategorie}
                items={categories}
                label="Seleccione una categoría"
            />
            {
                selectedCategorie ? 
                <NMSelect 
                    val={selectedSubcategorie}
                    setVal={setSelectedSubcategorie}
                    items={subcategories.filter(el=>el.id_categorie===selectedCategorie.value)}
                    label="Selecciona una subcategoría"
                /> : null
            }
            <span style={{color:theme.colors.primary}}>
                Descripción del video.
            </span>
            <textarea 
                className="form-control"
                cols="40" 
                rows="5" 
                value={description}
                onChange={(e)=>setDescription(e.target.value)}
                style={{
                    marginTop:10,
                    marginBottom:10
                }}
            />
            <div className="d-flex justify-content-center">
                <Button 
                    label="Crear"
                    load={isCreating}
                    execute={execute}
                    disabled={checkErrors()}
                />
            </div>
        </Fragment>
    )
}

export default ContentCreate;