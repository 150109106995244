import React from 'react';
import { usePage } from '../../Context/Page/Store';
const Price = React.createContext();
export const PriceProvider = (props)=>{
    const {refreshPageData} = usePage();
    const [showModal,setShowModal] = React.useState(false);
    const [dataModal,setDataModal] = React.useState(null);
    const handleModal = (param)=>{
        if(param){
            openModal(param)
        }else{
            closeModal();
        }
    }
    const openModal = (params)=>{
        setDataModal(params);
        setShowModal(true);
    }
    const closeModal = ()=>{
        setShowModal(false);
        setDataModal(null);
        refreshPageData({load:false})
    }
    const cutString = (str)=>{
        if(str){
            return str.substring(0, 10) + "...";
        }else{
            return ""
        }
    }
    const value = React.useMemo(()=>{
        return({
            showModal,
            dataModal,
            openModal,
            closeModal,
            handleModal,
            cutString
        })
    },[showModal,dataModal])
    return <Price.Provider value={value} {...props}/>
}
export const usePrice = ()=> React.useContext(Price);