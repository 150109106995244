import React, { Fragment } from "react";
import "./styles.scss";
const Input = ({value,setValue,label,placeholder,type,error,containerStyle,disabled})=>{
    const identificador = `input__${Math.random()}`;
    return(
        <Fragment>
            <div className="form__group" style={{marginTop:-15,...containerStyle}}>
                <input 
                    type={type||"input"} 
                    className="form__field"
                    value={value}
                    onChange={(e)=>setValue(e.target.value)}
                    placeholder={placeholder||""} 
                    name={identificador} 
                    id={identificador} 
                    disabled={disabled||false}
                />

                {
                    error?
                    <p className="form__error">
                        {error}
                    </p> : <div style={{height:15}}></div>
                }
                <label htmlFor={identificador} className="form__label">{label}</label>
            </div>
        </Fragment>
    )
}
export default Input;