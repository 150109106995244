import React from "react";
import ContentCreate from "./ContentCreate";
import ModifyName from "./ModifyName";
import ModifyStatus from "./ModifyStatus";
import ModifyDescription from "./ModifyDescription";
import ModifyImage from "./ModifyImage";

const ContentModal = (props)=>{
    const {dataModal} = props;
    switch (dataModal.idPermit) {
        case null:
            return <ModifyStatus {...props} />
        case 30:
            return <ModifyName {...props} />
        case 31:
            return <ModifyDescription {...props} />
        case 34:
            return <ModifyImage {...props} />
        default:
            return <ContentCreate {...props}/>
    }
}
export default ContentModal;