import React,{ Fragment } from "react";

const ContentTabInfo = ({handle})=>{

    return(
        <Fragment>
            ESTAS EN CONTENT TAB INFO
        </Fragment>
    )
}
export default ContentTabInfo;