import React, { Fragment } from "react";
import { Container } from "react-bootstrap";
import { FullLoader } from "../../Components/Elements/Loader";
import { PageProvider,usePage } from "./Store";
import NavBar from "../../Components/Navbar";
import Card from "../../Components/Elements/Card";
import "./styles.css";
import ErrorContainer from "../../Components/Elements/ErrorContainer";

const PageModel = (props)=>{
    const {url,postData,includeCard} = props;
    const url_img_bg = process.env.PUBLIC_URL + '/img/fondo_nm_admin.png';
    return(
        <Fragment>
            <PageProvider url={url} postData={postData}>
                <PageTunnel url={url}>
                    <div className="principal" style={{backgroundImage:`url(${url_img_bg})`,position:"fixed",overflowY:"auto"}}>
                        <NavBar />
                        <div className="secondary">
                            <Container>
                                {
                                    includeCard ? 
                                        <Card style={{flex:1,overflowY:"auto",paddingTop:10}}>
                                            {props.children||<p>Contenido no especificado</p>}
                                        </Card> : props.children||<p>Contenido no especificado</p>
                                }
                            </Container>
                        </div>
                    </div>
                </PageTunnel>
            </PageProvider>
        </Fragment>
    )
}

const PageTunnel = (props)=>{
    const {url} = props;
    const {pageData,pageLoad,pageError} = usePage();
    if(pageLoad){
        return <FullLoader msg="Obteniendo información.." />
    }
    if(pageError){
        return <ErrorContainer msg={pageError} />
    }
    return(
        <Fragment>
            {
                url!==null?
                    pageData ? 
                        props.children : 
                            <ErrorContainer msg={"Permiso no autorizado"} /> : 
                                props.children
            }
        </Fragment>
    )
}
export default PageModel;