import React,{useState} from 'react';
import { REQUEST, Toast, useSystem } from '../../../Context/System';
const TabContext = React.createContext();
export const TabContextProvider = (props)=>{
    const {url,postData} = props;
    const {user} = useSystem();

    const [tabData,setTabData] = useState(null);
    const [tabError,setTabError] = useState(null);
    const [tabLoader,setTabLoader] = useState(false);
    const handleTabLoader = (arg)=>setTabLoader(arg);

    const refreshTabInfo = async(load)=>{
        let r = await REQUEST({
            url:url,
            params:{
                user,
                ...postData
            },
            load:load?handleTabLoader:null
        })
        if(r){
            if(r.error){
                setTabError(r.error);
            }else{
                setTabData(r.result);
            }
        }
    }

    React.useEffect(()=>{
        if(url!==null){
            refreshTabInfo(true);
        }
    },[url])
    const value = React.useMemo(()=>{
        return({
            tabData,
            tabError,
            tabLoader,
            refreshTabInfo
        })
    },[tabData,tabError,tabLoader])
    return <TabContext.Provider value={value} {...props}/>
}
export const useTabContext = ()=> React.useContext(TabContext);