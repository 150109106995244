import React, { Fragment,useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FaDollarSign } from "react-icons/fa";
import Button from "../../../Components/Elements/Button";
import Input from "../../../Components/Elements/Input";
import NMSelect from "../../../Components/Elements/Select";
import { REQUEST, Toast, useSystem } from "../../../Context/System";
import { usePrice } from "../Context";
import {number_format} from "locutus/php/strings";
import { usePage } from "../../../Context/Page/Store";
import Selector from "../../../Components/Elements/Select";

const ContentCreate = (props)=>{
    const {handleModal,dataModal} = usePrice();
    const {pageData} = usePage();
    const {categories} = pageData;
    const {user,theme} = useSystem();
    const [name,setName] = useState("");
    const [cost,setCost] = useState("");
    const [description,setDescription] = useState("");
    const [selectedCategories,setSelectedCategories] = useState(null);
    const [isCreating,setIsCreating] = useState(false);
    const handleCreating = (param)=>setIsCreating(param);
    const checkName = ()=>{
        if(name&&name.length>=3){
            return null
        }else{
            return "El nombre debe contener al menos 3 caracteres."
        }
    }
    const checkDescription = ()=>{
        if(description&&description.length>=10){
            return null
        }else{
            return "La descripción debe contener al menos 10 caracteres."
        }
    }
    const checkCost = ()=>{
        if(cost&&!isNaN(cost)){
            return null
        }else{
            return "Formato de costo inválido."
        }
    }
    const checkErrors = ()=>{
        let valid = true;
        let check_1 = !checkName();
        let check_2 = !checkDescription();
        let check_3 = !isNaN(cost);
        let check_4 = (selectedCategories&&selectedCategories.length>0);
        if(check_1&&check_2&&check_3&&check_4){
            valid = false;
        }
        return valid;
    }
    const getCost = ({formated})=>{
        let obj = {
            base:0,
            tax:0
        }
        if(cost&&!isNaN(cost)){
            let cost_no_tax = Math.round((cost / 1.16)*1000)/1000;
            let tax = Math.round((cost_no_tax*.16)*1000)/1000;
            if(formated){
                obj.base = `$${number_format(cost_no_tax,2)}`;
                obj.tax = `$${number_format(tax,2)}`;
            }else{
                obj.base = parseFloat(number_format(cost_no_tax,2));
                obj.tax = parseFloat(number_format(tax,2));
            }
        }else{
            if(formated===true){
                obj.base = "Formato de costo inválido";
                obj.tax = "Formato de costo inválido";
            }
        }
        return obj;
    }
    const execute = async()=>{
        let r = await REQUEST({
            url:"admin-request",
            params:{
                type:"create-price",
                user,
                name,
                description,
                cost:getCost({formated:false}),
                categories:selectedCategories
            },
            load:handleCreating
        })
        if(r){
            if(r.error){
                Toast({
                    message:r.error,
                })
            }else{
                Toast({
                    message:"Precio creado exitosamente.",
                    type:"success"
                })
                handleModal(false);
            }
        }
    }
    return(
        <Fragment>
            <Row>
                <Col xs="12" md="6">
                    <Input 
                        value={name}
                        setValue={setName}
                        label="Nombre del precio"
                        error={checkName()}
                    />
                </Col>
                <Col xs="12" md="6">
                    <Input 
                        value={cost}
                        setValue={setCost}
                        label="Costo total"
                        error={checkCost()}
                    />
                </Col>
                <Col xs="12" md="6">
                    <Input 
                        value={getCost({formated:true}).base}
                        disabled
                        label="Costo Base"
                    />
                </Col>
                <Col xs="12" md="6">
                    <Input 
                        value={getCost({formated:true}).tax}
                        disabled
                        label="Impuesto (IVA)"
                    />
                </Col>
                <Col xs="12">
                    <Selector
                        label="Seleccionar categorías"
                        placeholder="Selecciona una o mas categorías."
                        val={selectedCategories}
                        setVal={setSelectedCategories}
                        items={categories}
                        multi
                    />
                </Col>
            </Row>
            <span style={{color:theme.colors.primary}}>
                Descripción del precio.
            </span>
            <textarea 
                className="form-control"
                cols="40" 
                rows="5" 
                value={description}
                onChange={(e)=>setDescription(e.target.value)}
                style={{
                    marginTop:10,
                    marginBottom:10
                }}
            />
            <div className="d-flex justify-content-center">
                <Button 
                    label="Crear"
                    load={isCreating}
                    execute={execute}
                    disabled={checkErrors()}
                />
            </div>
        </Fragment>
    )
}

export default ContentCreate;
