import React, {Fragment, useState} from "react";
import {Row,Col} from "react-bootstrap"
import Button from "../../Components/Elements/Button";
import Input from "../../Components/Elements/Input";
import CustomModal from "../../Components/Elements/Modal";
import { usePage } from "../../Context/Page/Store";
import { useSystem } from "../../Context/System";
import ContentModalPermits from "./ContentModalPermits";
import ContentModalCreate from "./ContentModalCreate";

const Content = (props)=>{
    const {theme,user} = useSystem();
    const {
        pageData, 
        //pageLoad,
        //pageError,
        //handlePageLoad,
        //NAVTO
    } = usePage();
    const {list} = pageData;
    const [search,setSearch] = useState("");
    const [selectedUsr,setSelectedUsr] = useState(null);
    const [modalState,setModalState] = useState(false);
    
    const [modalCreate,setModalCreate] = useState(false);
    const handleModalCreate = (arg)=>setModalCreate(arg);

    const handleModal = (usr)=>{
        if(usr){
            setSelectedUsr(usr);
        }else{
            setSelectedUsr(null);
        }
        setModalState(!modalState);
    }

    const filteredList = ()=>{
        if(search){
            let searching = search.trim().toLowerCase();
            let filteredResult = list.filter(el=>(`${el.names.toLowerCase()} ${el.s_names.toLowerCase()}`).indexOf(searching)!==-1);
            return filteredResult;
        }else{
            return list;
        }
    }
    return(
        <Fragment>
            {
                selectedUsr ? 
                <CustomModal 
                    active={modalState}
                    handle={handleModal}
                    postObj={{
                        type:"get-modules-permits",
                        user,
                        id_user:selectedUsr.id_user
                    }}
                    url={"admin-request"}
                    children={<ContentModalPermits selected={selectedUsr} />}
                    title={`Permisos "${selectedUsr.names} ${selectedUsr.s_names}"`}
                    size="xl"
                    center
                /> : null
            }
            {
                modalCreate ? 
                <CustomModal 
                    active={modalCreate}
                    handle={handleModalCreate}
                    postObj={{
                        type:"get-create-admin-data",
                        user
                    }}
                    url={"admin-request"}
                    children={<ContentModalCreate handle={handleModalCreate} />}
                    title={`Crear Administrador`}
                    size="xl"
                    center
                /> : null
            }
            <Row>
                <Col xs="12" md="10">
                    <Input 
                        label="Buscar"
                        value={search}
                        setValue={setSearch}
                        placeholder="Buscar por nombre"
                    />
                </Col>
                <Col xs="12" md="2">
                    <div className="d-flex justify-content-end">
                        <Button 
                            label="Crear Administrador"
                            execute={()=>handleModalCreate(true)}
                        />
                    </div>
                </Col>
                <Col xs="12">
                    <div  className="table-container">
                        <table className="table table-hover">
                            <thead>
                                <tr align="center">
                                    <th style={{width:"25%",color:theme.colors.primary}}>
                                        Usuario
                                    </th>
                                    <th style={{width:"25%",color:theme.colors.primary}}>
                                        Correo
                                    </th>
                                    <th style={{width:"25%",color:theme.colors.primary}}>
                                        Tipo de usuario
                                    </th>
                                    <th style={{width:"25%",color:theme.colors.primary}}>
                                        Permisos
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    filteredList().map((usr,indexUsr)=>(
                                        <tr align="center" key={indexUsr}>
                                            <td style={{color:theme.colors.secondary}}>
                                                {
                                                    `${usr.names} ${usr.s_names}`
                                                }
                                            </td>
                                            <td style={{color:theme.colors.secondary}}>
                                                {
                                                    `${usr.email}`
                                                }
                                            </td>
                                            <td style={{color:theme.colors.secondary}}>
                                                {
                                                    `${usr.userType}`
                                                }
                                            </td>
                                            <td>
                                                <Button 
                                                    label="Cambiar Permisos"
                                                    execute={()=>handleModal(usr)}
                                                /> 
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </Col>
            </Row>
        </Fragment>
    )
}
export default Content;