import React from "react";
import { RingLoader } from "react-spinners";
import { Toast, useSystem } from "../../../Context/System";
import "./styles.css"
import DefaultConfig from "../../../Context/System/preset";
const Button = (props)=>{
    const {label,type,load,execute,disabled} = props;
    const {theme} = useSystem();
    const {colors} = theme;
    const getColor = ()=>{
        switch (type) {
            case "secondary":
                return colors.secondary;
            case "danger":
                return colors.gray
            default:
                return colors.primary;
        }
    }
    const buttonStyles = {
        backgroundColor:disabled?"#a1a1a1":getColor(),
        color:"#fff",
        transition: !disabled ? "all .5s ease" : null,
        cursor:!disabled? "pointer" : "no-drop"
    }
    const exe = ()=>{
        if(!disabled){
            if(execute){
                execute()
            }else{
                Toast({message:"No se asigno ninguna funcion a ejecutar"})
            }
        }
    }
    const returnClass = ()=>{
        let btnClass = "custom-button";
        if(load){
            btnClass += " custom-button-load";
        }
        if(disabled){
            btnClass += " custom-button-disabled";
        }
        return btnClass;
    }
    return(
        <div className="container-btn">
            <button onClick={exe} type="button" className={returnClass()} style={buttonStyles}>
                {   load ? 
                    <div style={{alignItems:"center",justifyContent:"center",height:24,width:20}}>
                        <RingLoader color={"#fff"} loading={true} css={{position:"absolute",marginLeft:-10}} size={20} /> 
                    </div> :
                    label||"Label no definido"
                }
            </button>
        </div>
    )
}
export default Button;