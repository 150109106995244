import React, { Fragment } from "react";
import PageModel from "../../Context/Page";
import Content from "./Content";

const Page = (props)=>{

    return(
        <PageModel 
            url={"admin-request"} 
            postData={{
                type:"get-subcategories",
                id_view:5
            }} 
        >
            <Content />
        </PageModel>
    )
}
export default Page;