import React, { Fragment } from 'react';
import AppContainer from './App/Components/AppContainer';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Toaster } from 'react-hot-toast';
const App = ()=>{
  return (
    <Fragment>
      <Toaster 
        position="bottom-center"
        reverseOrder={false}
      />
      <AppContainer />
    </Fragment>
  );
}

export default App;
