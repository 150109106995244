import React from "react";
import {Modal as BootstrapModal} from "react-bootstrap";

const Modal = (props)=>{
  const {title,show,handleClose,children,centrado,size} = props;
  return(
    <>
    <BootstrapModal size={(size)?size:null} show={show} onHide={handleClose} aria-labelledby={"modal"+title} centered={(centrado)?centrado:false}>
      <BootstrapModal.Header style={{background:"linear-gradient(90deg, rgb(118, 29, 144) 0%,rgb(56, 6, 109) 100%)"}}>
        <BootstrapModal.Title style={{color:"#fff"}}>{title}</BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        {
          (children)?
          children
          :
          <div className="d-flex justify-content-center">
            <p style={{fontWeight:"bold",fontSize:20}}>
              El modal no tiene contenido
            </p>
          </div>
        }
      </BootstrapModal.Body>
    </BootstrapModal>
    </>
  )
}

export default Modal;
