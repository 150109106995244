import React, { Fragment,useState } from "react";
import {Row,Col} from "react-bootstrap";
import Button from "../../../Components/Elements/Button";
import Input from "../../../Components/Elements/Input";
import { REQUEST, Toast, useSystem } from "../../../Context/System";
import { usePrice } from "../Context";
import {number_format} from "locutus/php/strings";

const ModifyCost = (props)=>{
    const {handleModal,dataModal} = usePrice();
    const {user} = useSystem();
    const actualValue = Math.round((dataModal.actualValue.base+dataModal.actualValue.tax)*100)/100;
    const [cost,setCost] = useState(actualValue);
    const [isCreating,setIsCreating] = useState(false);
    const handleCreating = (param)=>setIsCreating(param);
    const checkCost = ()=>{
        if(cost&&!isNaN(cost)){
            if(actualValue!==parseFloat(cost)){
                return null
            }else{
                return "Los valores no han cambiado."
            }
        }else{
            return "Formato de costo inválido."
        }
    }
    const execute = async()=>{
        let r = await REQUEST({
            url:"admin-request",
            params:{
                type:"update-price-cost",
                user,
                cost:getCost({formated:false}),
                id:dataModal.id
            },
            load:handleCreating
        })
        if(r){
            if(r.error){
                Toast({
                    message:r.error,
                })
            }else{
                Toast({
                    message:"Costo actualizado exitosamente.",
                    type:"success"
                })
                handleModal(false);
            }
        }
    }
    const getCost = ({formated})=>{
        let obj = {
            base:0,
            tax:0
        }
        if(cost&&!isNaN(cost)){
            let cost_no_tax = Math.round((cost / 1.16)*1000)/1000;
            let tax = Math.round((cost_no_tax*.16)*1000)/1000;
            if(formated){
                obj.base = `$${number_format(cost_no_tax,2)}`;
                obj.tax = `$${number_format(tax,2)}`;
            }else{
                obj.base = parseFloat(number_format(cost_no_tax,2));
                obj.tax = parseFloat(number_format(tax,2));
            }
        }else{
            if(formated===true){
                obj.base = "Formato de costo inválido";
                obj.tax = "Formato de costo inválido";
            }
        }
        return obj;
    }
    const checkErrors = ()=>{
        let valid = true;
        if(!checkCost()){
            valid = false;
        }
        return valid;
    }
    return(
        <Fragment>
            <Row>
                <Col xs="12">
                    <Input 
                        value={cost}
                        setValue={setCost}
                        label="Costo total"
                        error={checkCost()}
                    />
                </Col>
                <Col xs="12" md="6">
                    <Input 
                        value={getCost({formated:true}).base}
                        disabled
                        label="Costo Base"
                    />
                </Col>
                <Col xs="12" md="6">
                    <Input 
                        value={getCost({formated:true}).tax}
                        disabled
                        label="Impuesto (IVA)"
                    />
                </Col>
            </Row>
            <div className="d-flex justify-content-center">
                <Button 
                    label="Actualizar costo cliente"
                    load={isCreating}
                    execute={execute}
                    disabled={checkErrors()}
                />
            </div>
        </Fragment>
    )
}

export default ModifyCost;