import React, {Fragment,useState} from "react";
import { Col, Row } from "react-bootstrap";
import Button from "../../../Components/Elements/Button";
import Input from "../../../Components/Elements/Input";
import { REQUEST, Toast, useSystem } from "../../../Context/System";
import md5 from "md5";
import { usePage } from "../../../Context/Page/Store";

const ContentModalCreate = ({handle})=>{
    const {refreshPageData} = usePage();
    const {user} = useSystem();
    const [name,setName] = useState("");
    const [s_name,setS_name] = useState("");
    const [email,setEmail] = useState("");
    const [pwd,setPwd] = useState("");
    const [pwd2,setPwd2] = useState("");
    const [loadCreate,setLoadCreate] = useState(false);
    const handleLoadCreate = (arg)=>setLoadCreate(arg);

    const errorEmail = ()=>{
        if(!email){
            return "Insertar correo electrónico"
        }else{
            let reg = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
            if (reg.test(email)) {
                return null;
            }else{
                return "Formato de correo electrónico inválido";
            }
        }
    }
    const checkPsw = ()=>{
        if(pwd){
            if(pwd.length>=8){
                return null;
            }else{
                return "La contraseña debe contener al menos 8 caracteres."
            }
        }else{
            return "Inserte la nueva contraseña"
        }
    }
    const checkPsw2 = ()=>{
        if(pwd2){
            if(pwd===pwd2){
                return null;
            }else{
                return "Las contraseñas no coinciden."
            }
        }else{
            return "repita la nueva contraseña"
        }
    }
    const checkFormErrors = ()=>{
        if(name&&s_name&&!errorEmail()&&!checkPsw()&&!checkPsw2()){
            return false;
        }
        return true;
    }
    const createAdmin = async()=>{
        let r = await REQUEST({
            url:"admin-request",
            params:{
                type:"create-admin",
                user,
                name,
                s_name,
                email,
                pwd:md5(pwd)
            },
            load:handleLoadCreate
        })
        if(r){
            if(r.error){
                Toast({
                    message:r.error
                });
            }else{
                Toast({
                    message:"Administrador creado exitosamente.",
                    type:"success"
                })
                handle(false);
                refreshPageData(true)
            }
        }
    }
    return(
        <Fragment>
            <Row>
                <Col xs="12" md="6">
                    <Input 
                    value={name}
                    setValue={setName}
                    placeholder="Nombre(s)"
                    label="Nombre(s)"  
                    error={name?null:"Insertar nombre"}
                    />
                </Col>
                <Col xs="12" md="6">
                    <Input 
                    value={s_name}
                    setValue={setS_name}
                    label="Apellido(s)"  
                    error={s_name?null:"Insertar apellido"}
                    />
                </Col>
                <Col xs="12">
                    <Input 
                    value={email}
                    setValue={setEmail}
                    label="Correo electrónico"  
                    error={errorEmail()}
                    />
                </Col>
                <Col xs="12" md="6">
                    <Input 
                    value={pwd}
                    setValue={setPwd}
                    label="Contraseña"  
                    error={checkPsw()}
                    type="password"
                    />
                </Col>
                <Col xs="12" md="6">
                    <Input 
                    value={pwd2}
                    setValue={setPwd2}
                    label="Repetir contraseña"  
                    error={checkPsw2()}
                    type="password"
                    />
                </Col>
                <Col xs="12">
                    <div className="d-flex justify-content-center">
                        <Button 
                            load={loadCreate}
                            label="Crear"
                            disabled={checkFormErrors()}
                            execute={createAdmin}
                        />
                    </div>
                </Col>
            </Row>
        </Fragment>
    )
}
export default ContentModalCreate;