import React from "react";
const Card = (props)=>{
    const {onClick,style,sizeable} = props;
    const styles = {
      cursor:onClick?"pointer":"auto",
      padding:10,
      borderRadius:15,
      background:"#fff",
      ...style
    }
    return(
      <>
        <div className={sizeable?"minisizeable":""} style={styles} onClick={onClick?()=>onClick():null}>
          {props.children}
        </div>
      </>
    )
  }
  export default Card;