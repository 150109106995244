import React,{useState} from "react";
import {Row,Col,Card} from "react-bootstrap";
import Button from "../../Components/Elements/Button";
import Input from "../../Components/Elements/Input";
import CustomModal from "../../Components/Elements/Modal";
import { usePage } from "../../Context/Page/Store";
import { useSystem } from "../../Context/System";
import ContentModal from "./ContentModal";

const Content = ()=>{
    const { theme,user,imageDoNotExist } = useSystem()
    const {pageData,refreshPageData} = usePage();
    const {list,categories,subcategories} = pageData;
    const [search,setSearch] = useState("");
    const [showModal,setShowModal] = useState(false);
    const [loadModal,setShowLoadModal] = useState(false);
    const [dataModal,setDataModal] = useState(null);
    const handleModal = (param)=>{
        if(param){
            openModal(param)
        }else{
            closeModal();
        }
    }


    const openModal = (params)=>{
        setShowLoadModal(false);
        setDataModal(params);
        setShowModal(true);
    }
    const closeModal = ()=>{
        setShowModal(false);
        setShowLoadModal(false);
        setDataModal(null);
        refreshPageData({load:false})
    }

    const filterList = ()=>{
        if(search){
            let s = search.toLocaleLowerCase();
            return list.filter((el)=>el.name_video.toLocaleLowerCase().indexOf(s)!==-1)
        }else{
            return list;
        }
    }
    const cutString = (str)=>{
        if(str){
            return str.substring(0, 10) + "...";
        }else{
            return ""
        }
    }
    return(
        <div style={{overflowX:"hidden"}}>
            {
                showModal ? 
                <CustomModal 
                    active={showModal}
                    handle={handleModal}
                    postObj={{
                        ...dataModal,
                        user
                    }}
                    url={"admin-request"}
                    children={<ContentModal dataModal={dataModal} handleModal={handleModal} />}
                    title={dataModal.title}
                    size="xl"
                    center
                /> : null
            }
            <div className="row">
                <div className="col-9">
                    <Input 
                        value={search}
                        setValue={setSearch}
                        label="Buscar por nombre."
                    />
                </div>
                <div className="col-3">
                    <div className="d-flex justify-content-end">
                        <Button 
                            label="Crear Video"
                            load={loadModal}
                            execute={()=>openModal({
                                type:"check-permit",
                                idPermit:17,
                                title: "Crear nuevo video.",
                                categories,
                                subcategories
                            })}
                        />
                    </div>
                </div>
            </div>
            <Row style={{padding:20}}>
                {
                    filterList().map((el,indexEl)=>(
                        <Col xs="12" md="6" key={indexEl} style={{marginTop:10}}>
                            <Card style={{ width: '100%' }}>
                                <Card.Img 
                                    variant="top" 
                                    style={{cursor:"pointer"}}
                                    className="minisizeable"
                                    src={el.image||imageDoNotExist}onClick={()=>openModal({
                                        type:"check-permit",
                                        idPermit:16,
                                        title: `Modificar imágen '${el.name_video}'`,
                                        image:el.image||imageDoNotExist,
                                        idVideo:el.value
                                    })}
                                />
                                <Card.Body>
                                    <Card.Title className="minisizeable" style={{cursor:"pointer"}} onClick={()=>openModal({
                                        type:"check-permit",
                                        idPermit:6,
                                        title: `Modificar nombre '${el.name_video}'`,
                                        actualValue:el.name_video,
                                        idVideo:el.value
                                    })}>
                                        {el.name_video}
                                    </Card.Title>
                                    <Row className="minisizeable" style={{cursor:"no-drop"}} >
                                        <Col xs="12" md="6">
                                            Subcategoría:
                                        </Col>
                                        <Col className="d-flex justify-content-end" xs="12" md="6">
                                        {el.name_subcategorie}
                                        </Col>
                                    </Row>
                                    <Row className="minisizeable" style={{cursor:"pointer"}} onClick={()=>openModal({
                                        type:"check-permit",
                                        idPermit:15,
                                        title: `Modificar descripción '${el.name_video}'`,
                                        actualValue:el.description,
                                        idVideo:el.value
                                    })}>
                                        <Col xs="12" md="6">
                                            Descripción:
                                        </Col>
                                        <Col className="d-flex justify-content-end" xs="12" md="6">
                                        {cutString(el.description)}
                                        </Col>
                                    </Row>
                                    <Row className="minisizeable" style={{cursor:"pointer"}} onClick={()=>openModal({
                                        type:"get-video-status-list",
                                        idPermit:null,
                                        title: `Modificar estatus '${el.name_video}'`,
                                        actualValue:{value:el.id_status,label:el.name_status},
                                        idVideo:el.value
                                    })}>
                                        <Col xs="12" md="6">
                                            Estatus:
                                        </Col>
                                        <Col className="d-flex justify-content-end" xs="12" md="6">
                                        {el.name_status}
                                        </Col>
                                    </Row>
                                    <Row style={{cursor:"no-drop"}}>
                                        <Col xs="12" md="6">
                                            Creado por:
                                        </Col>
                                        <Col className="d-flex justify-content-end" xs="12" md="6">
                                            {el.created_names}
                                        </Col>
                                    </Row>
                                    <Row style={{cursor:"no-drop"}}>
                                        <Col xs="12" md="6">
                                            Creado en:
                                        </Col>
                                        <Col className="d-flex justify-content-end" xs="12" md="6">
                                            {el.created_date.replace("T", " / ").replace("Z","").replace(".000","")}
                                        </Col>
                                    </Row>
                                    <Row style={{cursor:"no-drop"}}>
                                        <Col xs="12" md="6">
                                            Modificado por:
                                        </Col>
                                        <Col className="d-flex justify-content-end" xs="12" md="6">
                                            {el.edited_names}
                                        </Col>
                                    </Row>
                                    <Row style={{cursor:"no-drop"}}>
                                        <Col xs="12" md="6">
                                            Última Modificación:
                                        </Col>
                                        <Col className="d-flex justify-content-end" xs="12" md="6">
                                            {el.last_modif.replace("T", " / ").replace("Z","").replace(".000","")}
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))
                }

            </Row>
        </div>
    )
}
export default Content;