import React,{Fragment,useState} from "react";
import { useModalContext } from "../../../Components/Elements/Modal/Store";
import {Tabs,Tab} from "react-bootstrap";
import ReturnIcon from "../../../Components/Elements/ReturnIcon";
import Button from "../../../Components/Elements/Button";
import { REQUEST, Toast, useSystem } from "../../../Context/System";

const ContentModalPermits = ({selected})=>{
    const {modalData,refreshInfo} = useModalContext();
    const {user} = useSystem();
    const {list} = modalData;
    const [loadPermit,setLoadPermit] = useState(false);
    const handleLoadPermit = (arg)=>setLoadPermit(arg);
    const updatePermit = async(permit)=>{
        let r = await REQUEST({
            url:"admin-request",
            params:{
                type:"update-permit",
                id_permit:permit.id_permit,
                value:!permit.auth,
                id_user:selected.id_user,
                user
            },
            load:handleLoadPermit
        })
        if(r){
            if(r.error){
                Toast({
                    message:r.error
                });
            }else{
                Toast({
                    message:"Permiso actualizado exitosamente",
                    type:"success"
                })
                refreshInfo();
            }
        }
    }
    return(
        <Fragment>
            <Tabs defaultActiveKey={0} id="tab-1">
                {
                    list.map((mod,iMod)=>(
                        <Tab eventKey={iMod} title={mod.label} variant="pills">
                            <Tabs defaultActiveKey={0} id="tab-1">
                                {
                                    mod.views.map((view,iView)=>(
                                        <Tab eventKey={iView} title={view.label}>
                                            <Tabs defaultActiveKey={0} id="tab-1">
                                                {
                                                    view.permits.map((permit,iPermit)=>(
                                                        <Tab eventKey={iPermit} title={permit.name_permit}>
                                                            <div className="d-flex justify-content-center p-5">
                                                                <Button 
                                                                    label={permit.auth?"Remover permiso":"Dar Permiso"}
                                                                    type={permit.auth?"danger":"secondary"}
                                                                    execute={()=>updatePermit(permit)}
                                                                    load={loadPermit}
                                                                />

                                                            </div>
                                                        </Tab>
                                                    ))
                                                }
                                            </Tabs>
                                        </Tab>
                                    ))
                                }
                            </Tabs>
                        </Tab>
                    ))
                }
            </Tabs>
        </Fragment>
    )
}
export default ContentModalPermits;

