import React, {Fragment,useState,useEffect} from "react";
import { useModalContext } from "../../../Components/Elements/Modal/Store";
import Selector from "../../../Components/Elements/Select";
import { usePage } from "../../../Context/Page/Store";
import { REQUEST, Toast, useSystem } from "../../../Context/System";
import { usePrice } from "../Context";

const ModifyCategories = (props)=>{
    const {dataModal,handleModal} = usePrice();
    const {id} = dataModal;
    const {user} = useSystem();
    const {pageData} = usePage();
    const {categories} = pageData;
    const [selectedCategorie,setSelectedCategorie] = useState(dataModal.actualValue);

    const changeCategoriesServer = async(value)=>{
        let r = await REQUEST({
            url:"admin-request",
            params:{
                type:"update-price-categories",
                categories:value,
                user,
                id
            }
        })
        if(r){
            if(r.error){
                Toast({
                    message:r.error
                })
            }else{
                Toast({
                    message:"Categorías actualizadas exitosamente.",
                    type:"success"
                })
                setSelectedCategorie(value);
            }
        }
    }
    return(
        <Fragment>
            <Selector 
                val={selectedCategorie}
                setVal={changeCategoriesServer}
                items={categories}
                label="Seleccione un categoría para agregar."
                multi
                dontShowX
            />
        </Fragment>
    )
}
export default ModifyCategories;