import { useEffect, useState, createContext, useContext, useMemo } from "react";
import { REQUEST, useSystem} from "../../../Context/System";
const ModalContext = createContext();

export const ModalContextProvider = (props)=>{
    const {url,postObj} = props;
    const {user} = useSystem();
    const [modalData,setModalData] = useState(null);
    const [modalLoad,setModalLoad] = useState(false);
    const [modalError,setModalError] = useState(null);
    const handleModalLoad = (param)=>setModalLoad(param);

    const refreshInfo = async(showLoader)=>{
        const r = await REQUEST({
            url:url,
            params:{
                user,
                ...postObj
            },
            load:showLoader?handleModalLoad:null
        });
        if(r){
            if(r.error){
                setModalError(r.error);
            }else{
                setModalData(r.result);
            }
        }
    }

    useEffect(()=>{
        if(!modalData&&url!=null){
            refreshInfo(true);
        }// eslint-disable-next-line
    },[])
    const value = useMemo(()=>{
        return({
           modalData,
           modalLoad,
           modalError,
           refreshInfo
        })// eslint-disable-next-line
    },[modalData,modalLoad,modalError])
    return <ModalContext.Provider value={value} {...props}/>
}
export const useModalContext = ()=> useContext(ModalContext);