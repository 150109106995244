import React, {Fragment} from "react";
import { ModalContextProvider,useModalContext } from "./Store";
import { Loader } from "../Loader";
import ErrorComponent from "../ErrorComponent";
import Modal from "./Modal";
import { useSystem } from "../../../Context/System";
const CustomModal = (props)=>{
    const {active,handle,url,children,title,center,size} = props;

    if(!active){
        return ""
    }
    return(
        <ModalContextProvider {...props}>
            <Modal size={size||"md"} show={active} title={title} handleClose={()=>handle(false)} centrado={center||false}>
                <ModalTunnel handle={handle} url={url}>
                    {children}
                </ModalTunnel>
            </Modal>
        </ModalContextProvider>
    )
}

const ModalTunnel = (props)=>{
    const {children,handle,url} = props;
    const {theme} = useSystem();
    const {modalLoad,modalData,modalError} = useModalContext();
    if(modalLoad){
        return(
            <Fragment>
                <div className="d-flex justify-content-center">
                    <Loader />
                </div>
                <p style={{color:theme.colors.secondary,textAlign:"center",fontWeight:"bold"}}>
                    Un Momento..
                </p>
            </Fragment>
        )
    }
    if(modalError||(!modalData&&url)){
        return(
            <Fragment>
                <ErrorComponent 
                    errorObj={{
                        errorMsg:modalError?modalError:"Error de programación",
                        tagPress:"Click para cerrar",
                        onPress:()=>handle(false),
                    }}
                />
            </Fragment>
        )
    }
    return(
        <Fragment>
            {children}
        </Fragment>
    )
}

export default CustomModal;