import React from "react";
import { useSystem } from "../System";
import { REQUEST,Toast } from "../System";
import { useHistory } from "react-router";


const PageContext = React.createContext();

export const PageProvider = (props)=>{
    const {postData,url} = props;
    const {constants,user} = useSystem();
    const [pageData,setPageData] = React.useState(null);
    const [pageLoad,setPageLoad] = React.useState(false);
    const [pageError,setPageError] = React.useState(null);
    const handlePageLoad = (param)=>setPageLoad(param);
    const history = useHistory();
    const NAVTO = ({to,params})=>history.push(to,{...params});
    const refreshPageData = async({load})=>{
        let r = await REQUEST({
            url:`${constants.backend}`+url,
            params:{user,...postData},
            load:load?handlePageLoad:null
        })
        if(r.error){
            setPageError(r.error);
            Toast({
                message:r.error
            });
        }else{
            setPageData(r.result);
        }
    }

    React.useEffect(()=>{
        if(!pageData){
          if(url!==null){
            refreshPageData({load:true});
          }
        }
    },[])
    const value = React.useMemo(()=>{
        return({
           pageData, 
           pageLoad,
           pageError,
           handlePageLoad,
           NAVTO,
           refreshPageData
        })
    },[pageData,pageLoad,pageError])
    return <PageContext.Provider value={value} {...props}/>
}

export const usePage = ()=> React.useContext(PageContext);